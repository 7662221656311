<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>用户</el-breadcrumb-item>
                    <el-breadcrumb-item>列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_headerSecondary">
                <div>
                    <vy-search-box v-model="searchVal" :btn-click="getData" placeholder="请输入内容"></vy-search-box>
                </div>
                <div></div>
            </div>

            <!-- 列表 -->
            <div class="page_content">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="id" align="center" label="id"> </el-table-column>
                    <el-table-column prop="account" align="center" label="账号"></el-table-column>
                    <el-table-column prop="legal_person" align="center" label="法人"></el-table-column>
                    <el-table-column prop="status" align="center" label="状态">
                        <template slot-scope="scope">
                            <span :style="{ color: scope.row.status ? '#b66dff' : '#fe7c96' }">{{
                                scope.row.status ? "启用" : "禁用"
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <div class="_operate">
                                <button @click="edit(scope.row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(scope.row)">删除</button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页器 -->
            <vy-pager
                :count="count"
                :page-size="pageSize"
                :page="currentPage"
                @current-change="change"
                @size-change="sizeChange"
            ></vy-pager>
            <!-- 编辑弹出框 -->
            <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="40%"
                custom-class="edit"
                :show-close="false"
                :before-close="bfc"
            >
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="账号">
                        <el-input v-model="form.account" disabled readonly></el-input>
                    </el-form-item>
                    <el-form-item label="法人">
                        <el-input v-model="form.legal_person"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model.trim="form.password" placeholder="该项为空时不会修改密码" show-password></el-input>
                    </el-form-item>
                    <!-- 如果修改的是管理员 -->
                    <el-form-item label="状态" v-if="form.id !== 1">
                        <el-switch
                            class="sw"
                            v-model="form.status"
                            active-color="#7781f1"
                            inactive-color="#ff4949"
                            active-text="启用"
                            inactive-text="禁用"
                        >
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="角色" v-if="form.id !== 1">
                        <el-select v-model="form.role_id" placeholder="请选择">
                            <el-option v-for="el in jsList" :key="el.id" :label="el.title" :value="el.id"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="yes_edit">提交</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import VyPager from "@/components/customs/VyPager.vue";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: { VyPager, VySearchBox },
    data() {
        return {
            // 当前登录账号的id
            id: "",
            // 当前的页
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            count: 0,
            form: {},
            dialogVisible: false,
            // 角色列表
            jsList: [],
            searchVal: "",
        };
    },
    async created() {
        await this.getData();
        this.jsList = await this.getData_js();
    },
    methods: {
        // 获取所有用户
        async getData() {
            let { searchVal, currentPage, pageSize } = this;
            let {
                data: { count, data },
            } = await axios.get("/api/logon/usershow", { params: { page: currentPage, searchVal, pageSize } });
            this.tableData = data;
            this.count = count;
        },
        // 获取所有角色
        async getData_js() {
            let { data } = await axios.get("/api/user_role_menu/rshow");
            return data;
        },
        // 当前页改变时
        change(currentPage) {
            this.currentPage = currentPage;
            this.getData();
        },
        sizeChange(currentPageSize) {
            this.pageSize = currentPageSize;
            this.getData();
        },
        // 打开编辑框
        edit(row) {
            this.form = {
                id: row.id,
                account: row.account,
                legal_person: row.legal_person,
                password: "",
                role_id: row.role_id,
                status: Boolean(row.status),
            };
            // 不能修改自己的状态和管理员的状态
            // this.$set(this.form, 'status', Boolean(row.status));
            this.dialogVisible = true;
        },
        // 确认编辑
        async yes_edit() {
            let { data } = await axios.put(
                "/api/logon/userupdate",
                qs.stringify({
                    ...this.form,
                    status: Number(this.form.status),
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            await this.getData();
            this.dialogVisible = false;
        },
        // 删除用户
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/logon/userdelete", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                await this.getData();
                this.$message.success(data.msg);
            }
            this.dialogVisible = false;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    /deep/.edit {
        border-radius: 20px;
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
