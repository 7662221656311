<template>
    <div class="vy-search-box" :style="{ borderColor: border ? '#7781f1' : '#fff' }">
        <template v-if="select">
            <el-select class="select" v-model="selectVal" :clearable="selectClearable" placeholder="请选择" @change="change">
                <template v-for="el in selectList">
                    <el-option :label="el.label" :value="el.value" :key="el.value"> </el-option>
                </template>
            </el-select>
        </template>
        <el-input
            v-model="iptVal"
            @keydown.enter.native="btnClick"
            @focus="border = true"
            @blur="border = false"
            :placeholder="placeholder"
            clearable
        ></el-input>
        <button class="button" @click="btnClick()">搜索</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            border: false,
            iptVal: "",
            selectVal: "",
        };
    },
    props: {
        btnClick: {
            type: Function,
            default: () => {},
        },
        value: [Number, String],
        placeholder: String,

        selectList: {
            type: Array,
            default: () => [],
        },
        selectValue: {
            type: [Number, String],
            default: "",
        },
        select: {
            type: Boolean,
            default: false,
        },
        selectClearable: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        this.iptVal = this.value;
        this.selectVal = this.selectValue;
    },
    methods: {
        change(val) {
            this.$emit("change", this.selectVal);
        },
    },
    watch: {
        iptVal(newVal) {
            this.$emit("input", newVal);
        },
    },
};
</script>

<style lang="less" scoped>
.vy-search-box {
    display: flex;
    align-items: center;
    padding: 2px;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    box-sizing: content-box;
    .select {
        width: 90px;
        height: 36px;

        /deep/ input {
            border-radius: 20px;
            border: none;
            height: 36px;
            line-height: 36px;
        }
    }
    .el-input {
        border: none;
        min-width: 200px;
        flex: 1;
        height: 36px;
        /deep/ input {
            border: none;
            height: 36px;
            line-height: 36px;
            border-radius: 20px;
        }
    }
    .button {
        width: 90px;
        height: 36px;
        color: white;
        border: 0;
        border-radius: 20px;
        background: linear-gradient(to right, #da8cff, #9a55ff);
        cursor: pointer;
    }
}
</style>
