<template>
    <!-- 分页器 -->
    <div class="main">
        <div>
            <span>
                {{ `显示第 ${start} 至 ${end} 项结果，共 ${count} 项` }}
            </span>
        </div>
        <!-- <el-pagination layout="prev, pager, next" :total="count" :page-size="pageSize" @current-change="cc"> </el-pagination> -->
        <!-- @size-change="handleSizeChange" -->
        <el-pagination
            @current-change="currentPage => $emit('current-change', currentPage)"
            @size-change="currentPageSize => $emit('size-change', currentPageSize)"
            :current-page="page"
            :page-sizes="[10, 20, 50, 80, 100, 300, 500]"
            :page-size="pageSize"
            layout=" sizes, prev, pager, next, jumper"
            :total="count"
            background
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "VyPager",
    props: {
        count: {
            /**
             * 总条数
             */
            type: Number,
            required: true,
        },
        pageSize: {
            /**
             * 一页的条数，默认为一页10条
             */
            type: Number,
            default: 10,
        },
        page: {
            /**
             * 当前页
             */
            type: Number,
            default: 1,
        },
    },
    computed: {
        start() {
            let { count, page, pageSize } = this;
            let p = (page - 1) * pageSize + 1;
            if (count == 0) p = 0;
            return p;
        },
        end() {
            let { count, page, pageSize } = this;
            let p = (page - 1) * pageSize + pageSize;
            if (p > count) p = count;
            return p;
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 24px;
    align-items: center;
    span {
        color: #666;
        font-size: 14px;
    }
    /deep/.el-pagination {
        border-radius: 20px;
        background-color: white;
        border: 1px solid #ccc;
        overflow: hidden;
        padding: 4px 15px;
        position: fixed;
        right: 30px;
        bottom: 15px;
        box-shadow: 1px 1px 10px 0px #ccc;
        .active {
            // color: #7781f1;
            background-color: #7781f1 !important;
            // color: #FFF;
        }
        li:hover {
            color: #7781f1 !important;
        }
        .el-input__inner:hover {
            border-color: #7781f1;
        }
    }
}
</style>
